<template>
    <div>
        <TitleBar>名片模板</TitleBar>
        <div class="main-container">
            <div class="data-panel">
                <div class="actions">
                    <Button type="primary" icon="md-add-circle" @click="$router.push({ name: 'card-template-add' })">新建名片</Button>
                </div>
                <Table :columns="columns" :data="dataList" class="data-table">
                    <template slot-scope="{ row }" slot="knowledgeShelfNames">
                        <span>{{ row.knowledgeShelfNames | knowledgeShelfNamesFilter }}</span>
                    </template>
                    <template slot-scope="{ row }" slot="departments">
                        <Tag
                            type="border"
                            style="background-color: rgba(0, 178, 166, 0.1) !important"
                            color="primary"
                            v-for="name in row.departmentNames"
                            :key="name"
                            >{{ name }}</Tag
                        >
                    </template>
                    <template slot="action" slot-scope="{ row }">
                        <button @click="editTemplate(row.id)"><i class="nazaio-iconfont nz-edit-square" /></button>
                        <button @click="remove(row.id)"><i class="nazaio-iconfont nz-delete" />删除</button>
                    </template>
                </Table>
                <div class="pagination" v-if="totalSize / query.pageSize > 1">
                    <Page
                        @on-change="pageChange"
                        :current="query.pageNum"
                        :total="totalSize"
                        :pageSize="query.pageSize"
                        show-elevator
                        show-total
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import { getList, remove } from "@/api/card-template";

export default {
    components: {
        TitleBar,
    },
    created() {
        this.getList();
    },
    filters: {
        knowledgeShelfNamesFilter(names) {
            if (names && names.length) {
                return names.join("；");
            }
            return "";
        },
    },
    data() {
        return {
            columns: [
                {
                    title: "模板名称",
                    key: "name",
                    // width: 240,
                },
                {
                    title: "内置知识货架",
                    slot: "knowledgeShelfNames",
                },
                {
                    title: "应用部门",
                    slot: "departments",
                },
                {
                    title: "更新时间",
                    key: "updateTime",
                    align: "center",
                    // width: 200,
                },
                {
                    title: "操作",
                    slot: "action",
                    align: "center",
                    // width: 200,
                },
            ],
            totalSize: 0,
            dataList: [],
            query: {
                pageNum: 1,
                pageSize: 10,
            },
        };
    },
    methods: {
        getList() {
            getList(this.query).then((response) => {
                this.totalSize = response.totalSize;
                this.dataList = response.data;
            });
        },
        pageChange(pageNum) {
            this.query.pageNum = pageNum;
            this.getList();
        },
        editTemplate(id) {
            this.$router.push({ path: `/social-card/card-template/edit/${id}` });
        },
        remove(id) {
            this.$Modal.confirm({
                title: "确定删除该名片模板吗？",
                onOk: () => {
                    remove(id).then(() => {
                        this.$Message.success("名片模板已成功删除！");
                        this.getList();
                    });
                },
            });
        },
    },
};
</script>

<style lang="less" scoped>
.actions {
    margin-bottom: 16px;
}
.data-panel {
    background: #ffffff;
    border-radius: 4px;
    padding: 16px;
}

.pagination {
    margin-top: 24px;
    text-align: right;
}

.data-table {
    button {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        width: 20px;
        line-height: 20px;
        height: 20px;
        color: #395069;
        margin: 0 5px;

        &:hover {
            color: @fc-main-color;
        }
    }
}
</style>